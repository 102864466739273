import { createAsyncAction } from "redux-promise-middleware-actions";
import {
  urlList,
  insuredUrlApi,
  validateJSONResponse,
  exceptionCodeResponse,
  urlBaseApi,
  validateFileResponse
} from "../utils/urls";
import {
  GET_SINISTER_POLICY_ITEMS,
  POST_SINISTER,
  GET_SINISTER_POLICY_DETAIL,
  GET_SINISTER_POLICY_COVERAGE,
  GET_SINISTER_COLLISION_TYPES,
  GET_FILES_SINISTER,
  GET_PDF_SINISTER
} from "../actions/types";
import { showSuccess, showError } from "../actions/notificationActions";
import { getToken } from "../utils/index";

export const postSinisterInsuredAPI = createAsyncAction(
  POST_SINISTER,
  async (token, query, dispatch) => {
    const options = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(query),
    };
    const res = await fetch(`${insuredUrlApi}sinister/`, options)
      .then((response) => {
        return validateJSONResponse(response);
      })
      .catch((error) => {
        dispatch(showError("El siniestro no se pudo guardar"));
        throw exceptionCodeResponse(error);
      });
    if (res.esValido) {
      dispatch(
        showSuccess(
          `El siniestro Nro. ${res.mensaje["Ejercicio"]}-${res.mensaje["Nro. Siniestro"]} fue generado con éxito`
        )
      );
    } else {
      dispatch(showError("El siniestro no se pudo guardar"));
    }
    return res;
  }
);

export const getSinisterPolicyDetailAPI = createAsyncAction(
  GET_SINISTER_POLICY_DETAIL,
  async (token, codigo_usuario, cod_ramo, nro_pol, fecha_ocurrencia) => {
    const options = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    const res = await fetch(
      `${insuredUrlApi}sinister/sinisterPolicyDetail?codigo_usuario=${codigo_usuario}&cod_ramo=${cod_ramo}&nro_pol=${nro_pol}&fecha_ocurrencia=${fecha_ocurrencia}`,
      options
    )
      .then((response) => {
        return validateJSONResponse(response);
      })
      .catch((error) => {
        throw exceptionCodeResponse(error);
      });
    return res;
  }
);

export const getSinisterPolicyCoverageAPI = createAsyncAction(
  GET_SINISTER_POLICY_COVERAGE,
  async (token, nro_pol, nro_endo, cod_ramo, cod_causa, cod_item) => {
    const options = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    const res = await fetch(
      `${insuredUrlApi}sinister/sinisterPolicyCoverage?nro_pol=${nro_pol}&nro_endo=${nro_endo}&cod_ramo=${cod_ramo}&cod_causa=${cod_causa}&cod_item=${cod_item}`,
      options
    )
      .then((response) => {
        return validateJSONResponse(response);
      })
      .catch((error) => {
        throw exceptionCodeResponse(error);
      });
    return res;
  }
);

export const getSinisterCollisionTypesAPI = createAsyncAction(
  GET_SINISTER_COLLISION_TYPES,
  async (token) => {
    const options = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    const res = await fetch(
      `${insuredUrlApi}sinister/sinisterCollisionTypes/`,
      options
    )
      .then((response) => {
        return validateJSONResponse(response);
      })
      .catch((error) => {
        throw exceptionCodeResponse(error);
      });
    return res;
  }
);

export const getFileSinisterByCodRamTecAPI = createAsyncAction(
  GET_FILES_SINISTER,
  async (cod_ram_tec, selectedCause) => {
    const token = getToken();

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
    };
    const res = await fetch(
      `${urlList}lists/consult/filessinister?cod_ram_tec=${cod_ram_tec}&causa=${selectedCause}`,
      options
    )
      .then((response) => {
        return validateJSONResponse(response);
      })
      .catch((error) => {
        throw exceptionCodeResponse(error);
      });
    return res;
  }
);

export const getSinisterPolicyItemsAPI = createAsyncAction(
  GET_SINISTER_POLICY_ITEMS,
  async (token, cod_ramo, nro_pol, fecha_ocurrencia) => {
    const options = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    const res = await fetch(
      `${insuredUrlApi}sinister/sinisterPolicyItems?cod_ramo=${cod_ramo}&nro_pol=${nro_pol}&fecha_ocurrencia=${fecha_ocurrencia}`,
      options
    )
      .then((response) => {
        return validateJSONResponse(response);
      })
      .catch((error) => {
        throw exceptionCodeResponse(error);
      });
    return res;
  }
);

export const downloadSinisterPdfAPI = createAsyncAction(
  GET_PDF_SINISTER,
  async (token, body) => {
    const data = body;
    const options = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    await fetch(`${urlBaseApi}sinister/sinisterGeneratePdf`, options)
      .then((response) => validateFileResponse(response))
      .then((blob) => {
        const nameFile = `Siniestro-${data.idStro}`
        // window.open(URL.createObjectURL(blob, "_blank"));
        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, nameFile);
        } else {
          const link = document.createElement("a");
          // Browsers that support HTML5 download attribute
          if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", nameFile);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      })
      .catch((error) => {
        throw console.log(error);
      });
  }
);